.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  grid-area: "header";
}

.App-link {
  color: #61dafb;
}

.wrapper {
  min-height: rem(900px);
  background-size: cover;
  background-image: url('./components/pexels-anniroenkae-7120424.jpg') !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



label {
  grid-area: 2 / 1 / span 1 / span 1;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

input#uploadPort {
  grid-area: 2 / 1 / span 1 / span 1;
  display: inline-flex;
  align-self: end;
  justify-self: center;
  padding-left: 75px;
}

.m-8983817 {
  position: relative !important;
  display: flex; /* Enable flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  height: 100%; /* Ensure the container takes the full height of the parent */
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.m-1b7284a3:not([data-with-border]){
  box-shadow: 0px 5px 20px rgba(0, 255, 81, 0.616);
  box-sizing: border-box;
  border-radius: 8px;
  
}

.m-8983817 .Base{
  position: absolute !important;
  left: 20% !important;
  width: 75% !important;
}

.Upload-Components {
  display: grid;
  grid-template: repeat(10, 1fr) / repeat(4, 1fr) 110px repeat(4, 1fr);
  height: 75%;
  width: 75% !important;
  position: fixed !important;
  align-self: center;
  justify-self: end;
  left: 17.5%;
  flex-shrink: 50;
  padding: 10px;
  flex: 1 1 auto;
}

.m-45252eee{
  flex: 0 1 auto !important;
}

.FileUpload {
  display: grid;
  grid-template: 1fr 1fr 1fr / 100%;
  height: 300px;
  width: 100%;
  border: thin solid lightgray;
  box-sizing: border-box;
  border-radius: 10px;
  grid-area: 2 / 1 / 5 / 10;
  text-align: center;
  justify-content: center;
}

.Upload {
  grid-area: 6 / 5 / span 1 / span 1;
  align-items: start;
}

.Footer {
  text-align: center;
  grid-area: "footer"
}

.Analysis-Container {
  position: relative !important;
  width: 25% !important;
  display: flex;
  justify-content: space-evenly;
}

.Analysis-Container .chart-container {
  position: absolute;
  bottom: 0;
  left: 0%;
  transform: translateX(-50%);
  height: 85%;
  width: 100% !important;
  max-width: 500px;
}

.Analysis-Container .chart-container canvas {
  width: 100% !important;
}

.Assignment-List{
  position: absolute;
  bottom: 0;
  height: 85%;
  left: 140%;
  transform: translateX(-50%);
  width: 150% !important;
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional: adds a border around the list */
  padding: 10px; /* Optional: adds padding inside the list */
}


.Teacher-Goals{
  position: relative;
  top: 0;
  height: 100%;
}

.Upload-Actual{
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}


  .acceptedFile{
    position: absolute;
    left: 10%;
    justify-self: center;
    box-sizing: border-box;
    border-color: whitesmoke;
    border-radius: 10px;
    z-index: 1;
    top: 22%;
    animation: anim-moving-glow 20s linear infinite;
  }

  .remove-button{
    position: absolute;
    top: 50%;
    right: 10%;
  }

  .proceedAnalysis{
    display: flex;
    z-index: 1;
    justify-self: center !important;
  }

  .Browse-File{
    position: absolute;
    top: 0;
  }

  .dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .dashboard-content {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    flex-grow: 1;
    padding: 20px;
    gap: 20px;
  }
  
  .student-section, .assignment-rates {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .student-section h2, .assignment-rates h2 {
    margin-bottom: 10px;
  }
  
  .missing-assignments-list ul {
    list-style: none;
    padding: 0;
  }
  
  .missing-assignments-list li {
    margin-bottom: 5px;
  }
  
  .export-button-container {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .export-button {
    padding: 10
  }  